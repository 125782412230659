import React, { useState } from "react";
import axios from "axios";

const client = axios.create({
  baseURL: `http://${
    process.env.NODE_ENV === "development"
      ? "localhost:3001"
      : window.location.host
  }/cslogin/`,
});

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    const res = await client.post("/",{
      username: email,
      password: password,
    });
    console.log(res);
  };
  return (
    <div className="page login">
      <form
        className="login-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <input
          className="form-input"
          placeholder="Enter username"
          value={email}
          type="text"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="form-input"
          placeholder="Enter password"
          value={password}
          type="text"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="btn__submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default Login;
