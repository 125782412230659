import React, { useState } from "react";
import validator from "validator";
import axios from "axios";
import Modal from "../Modal";

const client = axios.create({
  baseURL: `http://${
    process.env.NODE_ENV === "development"
      ? "localhost:3000"
      : window.location.host
  }/subscribe`,
});

function Form(props) {
  const [emailSubmitionErr, setEmailSubmitionErr] = useState(false);
  const [emailSubmitionSuccess, setEmailSubmitionSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);

  const handleFormSubmition = async (e) => {
    e.preventDefault();
    try {
      setEmail("");
      setShow(false);
      setEmailSubmitionSuccess(true);
      console.log({ email: email });
    } catch (error) {
      console.log({ error: error });
    }
  };

  return (
    <div style={{ display:"none"}}>
      <form
        className="subscribe-form"
        style={{ display: emailSubmitionSuccess && "none" }}
      >
        <input
          className="form-input"
          placeholder="Subscribe to our newsletter"
          value={email}
          onChange={(e) => {
            setEmailSubmitionErr(false);
            setEmail(e.target.value);
          }}
        />
        <button
          type="submit"
          className="btn__submit"
          onClick={(e) => {
            e.preventDefault();
            validator.isEmail(email)
              ? setShow(true)
              : setEmailSubmitionErr(true);
          }}
        >
          SUBMIT
        </button>
      </form>
      {emailSubmitionErr && <h3>Please Enter a valid email</h3>}
      {emailSubmitionSuccess && <h3>Thanks! you won't regret it!</h3>}
      <Modal
        show={show}
        onClose={() => setShow(false)}
        handleSubmit={handleFormSubmition}
      />
    </div>
  );
}

export default Form;
