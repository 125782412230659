import "./App.css";
import { Routes, Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home";
import Delete from "./components/pages/Delete";
import PrivateRoutes from "./utils/PrivateRoutes";
import Login from "./components/pages/Login";

function App() {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/delete" element={<Delete />} />
      </Route>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
