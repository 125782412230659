import React from "react";

function Modal(props) {
  if (!props.show) {
    return null;
  }

  return (
    <div className="modal" onClick={props.onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <div className="modal-body">
            מילוי טופס הצטרפות זה מהווה אישור והסכמה לקבלת מידע ועדכונים
            שיווקיים ופרסומיים באמצעים דיגיטליים לרבות דוא"ל ומסרונים ביחס לכלל
            חנויות FIVE POINT FOUR.
            <br />
            <br />
            ידוע לי ואני מסכים כי במידה ואני חבר כיום או אהיה חבר בעתיד, בנוסף
            למועדון FIVE POINT FOUR גם במועדון חנויות רשת פקטורי 54, החברה תהיה
            רשאית לעשות שימוש במידע אודותי ממועדון הלקוחות ברשת FIVE POINT FOUR,
            גם ביחס לדיוורים ופרסומים שיווקיים שישלחו אליי מטעם פקטורי 54, וזאת
            לצורך שיפור והתאמת השירות המסופק לו וכן למטרות המפורטות ביחס לכך
            בתקנון המועדון.
            <br />
            <br />
            ידוע לי כי אוכל להודיע לרשת בכל עת על ביטול הסכמתי לעיל, וזאת
            באמצעות הודעה בכתב לכתובת הרשת או השארת פרטים מזהים לחזרה בתיבת
            שירות הלקוחות בטלפון 03-6828430 או במייל cs@fivepointfour.co.il.
          </div>
          <div className="modal-footer">
            <button
              onClick={(e) => props.handleSubmit(e)}
              className="btn__modal"
            >
              אישור
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
