import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { FaInstagram } from "react-icons/fa";
import comingSoon from "../../videos/comingSoon.mp4";
import comingSoonMobile from "../../videos/comingSoon-mobile.mp4";
import { ReactComponent as ReactLogo } from "../../videos/logo.svg";
import Form from "../Forms/Form";

function Home() {
  const [isDesktop, setIsDesktop] = useState(false);
 
  useEffect(() => {
    const media = window.matchMedia("(min-width: 960px)");
    const listener = () => setIsDesktop(media.matches);
    listener();
    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  }, [isDesktop]);

  return (
    <div className="App">
      {/* <Accessibility/> */}
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={isDesktop ? comingSoon : comingSoonMobile}
          loop={true}
          autoPlay={true}
          width="100%"
          height="auto"
          muted={true}
          playing={true}
          playsinline={true}
        />
      </div>
      <div className="container">
        <div className="logo-wrapper">
          <ReactLogo className="logo-picture" />
          {/* <img src={logo} alt="logo" className="logo" /> */}
          <h1 className="coming-soon-title">COMING SOON</h1>
        </div>
        <div className="join-wrapper">
          <p className="join__text">Some things are for members only.</p>
          <a
            href="https://www.fivepointfour.com/joinclub/?w/"
            className="join__link"
          >
            <h3>JOIN US</h3>
          </a>
        </div>
        <Form />
        <a
          href="https://instagram.com/five.four?igshid=NmNmNjAwNzg="
          className="insta__link"
        >
          <div className="icons">
            <FaInstagram size={"1.3rem"} /> <h3>five.four</h3>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Home;
