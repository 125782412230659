import React from "react";
import { useState } from "react";
import validator from "validator";

function Delete() {
  const [email, setEmail] = useState("");
  const handleSubmit = async (e) => {
    try {
      if (!validator.isEmail(email)) {
        console.log(`please enter a valid email address`);
        return;
      }
      setEmail("");
      console.log({ email: email });
    } catch (error) {
      console.log({ error: error });
    }
  };

  return (
    <div className="page delete">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="delete-form"
        >
        <h3>הכנס כתובת מייל למחיקה מרשימת דיוור</h3>
        <input
          className="form-input"
          placeholder="הכנס מייל"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="btn__submit" type="submit">
          SUBMIT
        </button>
      </form>
    </div>
  );
}

export default Delete;
